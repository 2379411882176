import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { screenSizes } from '../../lib/constants'

const SlideBlock = styled.div`
    background-image: url('${(props) => props.bgImage}');
    background-position: center;
    background-size: cover;
    color: #fff;
    font-size: 36px;
    height: 70vh;
    margin: 15px;
    position: relative;
    text-align: center;

    @media (max-width: ${screenSizes.md}px) {
        height: 50vh;
    }
`

const Slide = (props) => {
    const { t } = useTranslation()

    return <SlideBlock {...props} className="slideBlock"></SlideBlock>
}

Slide.propTypes = {
    title: PropTypes.string,
    bgImage: PropTypes.string,
    excerpt: PropTypes.string,
    slug: PropTypes.string,
}

Slide.defaultProps = {
    title: 'Some title',
    bgImage: 'https://via.placeholder.com/1920x1080',
    excerpt: 'some text',
    slug: 'some-slug',
}

export default Slide
